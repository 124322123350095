import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import ContentWrapper from "../../ContentWrapper";
import Heading from "../../Heading";
import Text from "../../Text";

const Wrapper = styled(ContentWrapper)`
  margin-top: ${theme.space("xxl")};
  margin-bottom: ${theme.space("xxl")};
`;

const TextWrapper = styled.div`
  ${mq("3")} {
    display: flex;
  }
`;

const TextItemWrapper = styled.div`
  ${mq("3")} {
    flex: 0 0 50%;

    &:first-of-type {
      padding-right: ${theme.space("s")};
    }

    &:last-of-type {
      padding-left: ${theme.space("s")};
    }
  }
`;

const Intro = styled(Text)`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

function TextBlockV3({
  title,
  textLeft,
  textRight,
  intro,
  html = false,
  ...props
}) {
  return (
    <Wrapper columns={8} {...props}>
      {title && (
        <Heading size={4} as="h2">
          {title}
        </Heading>
      )}
      {intro && <Intro html={html}>{intro}</Intro>}
      <TextWrapper>
        <TextItemWrapper>
          <Text html={html}>{textLeft}</Text>
        </TextItemWrapper>
        <TextItemWrapper>
          <Text html={html}>{textRight}</Text>
        </TextItemWrapper>
      </TextWrapper>
    </Wrapper>
  );
}

TextBlockV3.propTypes = {
  title: PropTypes.string,
  textLeft: PropTypes.string,
  textRight: PropTypes.string,
  intro: PropTypes.string,
  html: PropTypes.bool,
};

export default TextBlockV3;
