import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import ContentWrapper from "../../ContentWrapper";
import Heading from "../../Heading";

const Wrapper = styled(ContentWrapper)`
  margin-top: ${theme.space("xxl")};
  margin-bottom: ${theme.space("xxl")};
`;

const TitleWrapper = styled.div`
  max-width: 310px;
`;

function TextBlockV2({ children, ...props }) {
  return (
    <Wrapper columns={10} {...props}>
      <TitleWrapper>
        <Heading size={4} as="h2">
          {children}
        </Heading>
      </TitleWrapper>
    </Wrapper>
  );
}

TextBlockV2.propTypes = {
  children: PropTypes.string,
};

TextBlockV2.defaultProps = {};

export default TextBlockV2;
