import styled from "@emotion/styled";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import ContentWrapper from "../ContentWrapper";

const Wrapper = styled(ContentWrapper)`
  margin-top: ${theme.space("xxl")};
  margin-bottom: ${theme.space("xxl")};

  > * {
    margin-bottom: ${theme.space("m")};
  }

  ${mq("3")} {
    display: flex;
    flex-direction: row-reverse;
  }
`;

export default Wrapper;
