import styled from "@emotion/styled";
import { string } from "prop-types";
import React from "react";

import theme from "../../theme";
import { bodySmall, h4 } from "../Typography";

const Wrapper = styled.blockquote`
  color: ${theme.color("blue.base")};
  margin: 0;
  padding: 0;
`;

const Copy = styled.p`
  ${h4};
  color: ${theme.color("primary.base")};
`;

const Author = styled.span`
  ${bodySmall};
`;

const Footer = styled.footer`
  color: ${theme.color("primary.base")};
`;

function QuoteComponent({ author, quote, ...props }) {
  return (
    <Wrapper {...props}>
      <Copy>
        &ldquo;
        {quote}
        &rdquo;
      </Copy>
      <Footer>
        <Author>{author}</Author>
      </Footer>
    </Wrapper>
  );
}

QuoteComponent.propTypes = {
  author: string.isRequired,
  cite: string,
  citeUrl: string,
  quote: string.isRequired,
};

QuoteComponent.defaultProps = {
  cite: null,
  citeUrl: null,
};

export default QuoteComponent;
