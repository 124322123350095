import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { shape } from "prop-types";
import React from "react";

import theme from "../../theme";
import { datoFile } from "../../types";
import ContentWrapper from "../ContentWrapper";
import ImageComponent from "../ImageComponent";
import { caption } from "../Typography";
import VerticalMargin from "../VerticalMargin";

const ImageTitle = styled.div`
  ${caption};
  position: absolute;
  top: 100%;
  left: ${theme.space("s")};
  padding-top: ${theme.space("xxs")};
  color: ${theme.color("dark.light")};
`;

const Image = ({ source }) => (
  <VerticalMargin>
    <ContentWrapper columns={12}>
      <ImageComponent {...source} />
      {source?.data?.title && <ImageTitle>{source.data.title}</ImageTitle>}
    </ContentWrapper>
  </VerticalMargin>
);

export const ImageType = {
  source: shape(datoFile),
};

export const imageFragment = graphql`
  fragment imageFragment on DatoCMS_ImageRecord {
    type: __typename
    id
    source {
      format
      imageUrl: url
      data: responsiveImage(imgixParams: { fit: crop, w: 1280, auto: format }) {
        ...responsiveImageFragment
      }
    }
  }
`;

Image.propTypes = ImageType;

export default Image;
