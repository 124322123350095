import { graphql } from "gatsby";
import { shape, string } from "prop-types";
import React from "react";

import ContentWrapper from "../ContentWrapper";
import DownloadComponent from "../DownloadComponent";
import { ImageType } from "../ImageComponent";
import VerticalMargin from "../VerticalMargin";

const Download = (props) => (
  <VerticalMargin>
    <ContentWrapper columns={12}>
      <DownloadComponent html {...props} />
    </ContentWrapper>
  </VerticalMargin>
);

export const downloadType = {
  newsletterUrl: string,
  image: shape(ImageType),
  text: string,
  title: string,
  buttonText: string,
};

export const downloadFragment = graphql`
  fragment downloadFragment on DatoCMS_DownloadRecord {
    type: __typename
    title
    text
    image {
      data: responsiveImage(
        imgixParams: { fit: crop, w: 600, h: 472, fm: jpg, auto: format }
      ) {
        ...responsiveImageFragment
      }
    }
    buttonText
    asset {
      url
      title
    }
    link
    linkLabel
  }
`;

Download.propTypes = downloadType;

export default Download;
