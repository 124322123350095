import { graphql } from "gatsby";
import { string } from "prop-types";
import React from "react";

import ContentWrapper from "../ContentWrapper";
import QuoteComponent from "../QuoteComponent";
import VerticalMargin from "../VerticalMargin";

const Quote = (props) => (
  <VerticalMargin>
    <ContentWrapper columns={8}>
      <QuoteComponent html {...props} />
    </ContentWrapper>
  </VerticalMargin>
);

export const QuoteType = {
  quote: string,
  author: string,
};

export const quoteFragment = graphql`
  fragment quoteFragment on DatoCMS_QuoteRecord {
    type: __typename
    id
    quote
    author
  }
`;

Quote.propTypes = QuoteType;

export default Quote;
