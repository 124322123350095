import { graphql } from "gatsby";
import { string } from "prop-types";
import React from "react";

import TextBlock from "../TextBlock/v2";
import VerticalMargin from "../VerticalMargin";

const NarrowTagline = ({ title, ...props }) => (
  <VerticalMargin {...props}>
    <TextBlock html>{title}</TextBlock>
  </VerticalMargin>
);

export const NarrowTaglineType = {
  title: string,
};

export const narrowTaglineFragment = graphql`
  fragment narrowTaglineFragment on DatoCMS_NarrowTaglineRecord {
    type: __typename
    id
    title
  }
`;

NarrowTagline.propTypes = NarrowTaglineType;

export default NarrowTagline;
