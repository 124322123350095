import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import ImageCarousel from "../ImageCarousel";
import { IMAGE_HEIGHT_LARGE, IMAGE_HEIGHT_SMALL } from "./constants";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background: ${theme.color("blue.light")};
  overflow: hidden;
`;

const TextWrapper = styled.div`
  flex: 0 0 50%;
`;

const InnerTextWrapper = styled.div`
  margin: 0 1rem;
  ${mq("3")} {
    width: 60%;
    margin: 0 1rem 0 30%;
  }
  ${mq("4")} {
    width: 30%;
    margin: 0 1rem 0 50%;
  }
`;

const CarouselWrapper = styled.div`
  flex: 0 0 50%;
  padding: 2rem 0;
  > div {
    margin: 0;
    > div {
      height: ${IMAGE_HEIGHT_SMALL}px;
      padding: 0;
    }
  }
  ${mq("3")} {
    > div {
      > div {
        height: ${IMAGE_HEIGHT_LARGE}px;
      }
    }
  }
`;

function InlineCarousel({ title, text, images, ...props }) {
  return (
    <Wrapper {...props}>
      <TextWrapper>
        <InnerTextWrapper>
          {title}
          {text}
        </InnerTextWrapper>
      </TextWrapper>

      <CarouselWrapper>
        <ImageCarousel images={images} noCaptions />
      </CarouselWrapper>
    </Wrapper>
  );
}

InlineCarousel.propTypes = {
  title: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

InlineCarousel.defaultProps = {
  images: [],
};

export default InlineCarousel;
