import styled from "@emotion/styled";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React from "react";

import usePrefersReducedMotion from "../../../hooks/usePrefersReducedMotion";
import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import Image, { ImageType } from "../../ImageComponent";
import Wrapper from "../Wrapper";

const Shutter = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${theme.color("primary")};
  z-index: 10;
`;

const ImageContentWrapper = styled(motion.div)`
  position: relative;
  ${mq("3")} {
    flex: 1 1 100%;
    margin-right: ${({ textLeft }) => (textLeft ? 0 : theme.space("xl"))};
  }

  ${mq("4")} {
    margin-right: ${({ textLeft }) => (textLeft ? 0 : theme.space("xxl"))};
  }
`;

const TextContentWrapper = styled(motion.div)`
  ${mq("3")} {
    flex: 1 1 50%;
    margin-right: ${({ textLeft }) => (textLeft ? theme.space("xxxl") : 0)};
  }
`;

const ImageWrapper = styled.div`
  height: 233px;

  ${mq("2")} {
    height: 472px;
  }

  ${mq("3")} {
    margin-bottom: 0;
  }
`;

const StyledImage = styled(Image)`
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

function ContentBlockV1({
  image,
  title,
  text,
  action,
  textLeft,
  visible = false,
  ...props
}) {
  const isSSR = typeof window === "undefined";
  const prefersReducedMotion = usePrefersReducedMotion();

  const variants = {
    show: {
      scaleX: 1,
      transition: {
        when: "beforeChildren",
        ...theme.get("animation.quickReveal.transition"),
      },
    },
    hide: {
      scaleX: 0,
      transition: {
        when: "afterChildren",
        ...theme.get("animation.quickReveal.transition"),
      },
    },
  };

  const shutterVariants = {
    show: {
      scaleX: 0,
      transition: theme.get("animation.quickReveal.transition"),
    },
    hide: {
      scaleX: 1,
      transition: theme.get("animation.quickReveal.transition"),
    },
  };

  const staggerContainerVariants = {
    hide: {},
    show: {
      transition: theme.get("animation.staggeredReveal.transition"),
    },
  };

  const staggerVariants = {
    hide: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <Wrapper {...props}>
      {textLeft ? (
        <>
          <ImageContentWrapper
            initial={prefersReducedMotion || isSSR ? false : "hide"}
            animate={visible || prefersReducedMotion ? "show" : "hide"}
            variants={variants}
            key={"image"}
            textLeft={textLeft}
            style={{ originX: 0 }}
          >
            <Shutter variants={shutterVariants} style={{ originX: 1 }} />
            <ImageWrapper>
              <StyledImage {...image} />
            </ImageWrapper>
          </ImageContentWrapper>

          <TextContentWrapper
            initial={prefersReducedMotion || isSSR ? false : "hide"}
            animate={visible || prefersReducedMotion ? "show" : "hide"}
            variants={staggerContainerVariants}
            textLeft={textLeft}
            key={"content"}
          >
            <motion.div variants={staggerVariants}>{title}</motion.div>
            <motion.div variants={staggerVariants}>{text}</motion.div>
            <motion.div variants={staggerVariants}>{action}</motion.div>
          </TextContentWrapper>
        </>
      ) : (
        <>
          <TextContentWrapper
            initial={prefersReducedMotion || isSSR ? false : "hide"}
            animate={visible || prefersReducedMotion ? "show" : "hide"}
            variants={staggerContainerVariants}
            textLeft={textLeft}
            key={"content"}
          >
            <motion.div variants={staggerVariants}>{title}</motion.div>
            <motion.div variants={staggerVariants}>{text}</motion.div>
            <motion.div variants={staggerVariants}>{action}</motion.div>
          </TextContentWrapper>
          <ImageContentWrapper
            initial={prefersReducedMotion || isSSR ? false : "hide"}
            animate={visible || prefersReducedMotion ? "show" : "hide"}
            variants={variants}
            key={"image"}
            textLeft={textLeft}
            style={{ originX: 0 }}
          >
            <Shutter variants={shutterVariants} style={{ originX: 1 }} />
            <ImageWrapper>
              <StyledImage {...image} />
            </ImageWrapper>
          </ImageContentWrapper>
        </>
      )}
    </Wrapper>
  );
}

ContentBlockV1.propTypes = {
  image: PropTypes.shape(ImageType),
  title: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  action: PropTypes.node,
  textLeft: PropTypes.bool,
  visible: PropTypes.bool,
};

ContentBlockV1.defaultProps = {
  textLeft: true,
};

export default ContentBlockV1;
