import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-flexbox-grid";

import theme from "../../../theme";
import mq from "../../../utils/mediaQuery";
import Button from "../../Button";
import ContentWrapper from "../../ContentWrapper";
import Heading from "../../Heading";
import Icon from "../../Icon";
import Introduction from "../../Introduction";
import ReplaceableLink from "../../ReplaceableLink";
import Text from "../../Text";
import { h4 } from "../../Typography";

const StyledReplaceableLink = styled(ReplaceableLink)`
  color: black;
`;

const Wrapper = styled(ContentWrapper)`
  margin-top: ${theme.space("xxl")};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${theme.space("xxs")};
`;

const StyledRow = styled(Row)`
  flex-direction: column-reverse;
  ${mq("3")} {
    flex-direction: row;
  }
`;

const StyledIntroduction = styled(Introduction)`
  p:first-of-type {
    margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "inherit" : 0)};
  }
`;

const SideLink = styled.span`
  ${h4};
  display: inline-block;

  padding-bottom: ${theme.space("xxs")};
  margin-top: ${theme.space("l")};

  ${mq("3")} {
    margin-top: 0;
  }
`;

function TextBlockV1({
  title,
  text,
  altHeading = false,
  introduction = false,
  html = false,
  sideLink,
  ...props
}) {
  return (
    <Wrapper columns={12}>
      <StyledRow>
        <Col xsOffset={4} xs={6} md={2} mdOffset={0}>
          {sideLink && (
            <>
              <SideLink>{sideLink.text}</SideLink>
              <StyledReplaceableLink as={sideLink.as}>
                <Button styling={"link"} color={"secondary"} as={"span"}>
                  <span>
                    {sideLink.type === "case" ? "bekijk case" : "lees artikel"}
                  </span>
                  <Icon type={"arrow"} />
                </Button>
              </StyledReplaceableLink>
            </>
          )}
        </Col>

        <Col mdOffset={1} md={8} {...props}>
          {title && (
            <StyledHeading size={introduction || altHeading ? 3 : 4} as={"h3"}>
              {title}
            </StyledHeading>
          )}
          {introduction ? (
            <StyledIntroduction hasMarginTop={!!title} html={html} small>
              {text}
            </StyledIntroduction>
          ) : (
            <Text html={html}>{text}</Text>
          )}
        </Col>
      </StyledRow>
    </Wrapper>
  );
}

TextBlockV1.propTypes = {
  sideLink: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  title: PropTypes.string,
  text: PropTypes.string,
  altHeading: PropTypes.bool,
  introduction: PropTypes.bool,
  html: PropTypes.bool,
};

export default TextBlockV1;
