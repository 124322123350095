import { graphql } from "gatsby";
import { shape, string } from "prop-types";
import React from "react";

import ContentBlock from "../ContentBlock/v3";
import { ImageType } from "../ImageComponent";

const FeaturedContent = ({ title, description, url, urlText, image }) => (
  // <VerticalMargin {...props}>
  <ContentBlock
    title={title}
    description={description}
    url={url}
    urlText={urlText}
    image={image}
  />
  // </VerticalMargin>
);

export const featuredContentType = {
  title: string,
  description: string,
  descriptionRight: string,
  url: string,
  urlText: string,
  image: shape(ImageType),
};

export const featuredContentFragment = graphql`
  fragment featuredContentFragment on DatoCMS_FeaturedContentRecord {
    type: __typename
    title
    description
    url
    urlText
    image {
      data: responsiveImage(imgixParams: { fit: crop, w: 524, auto: format }) {
        ...responsiveImageFragment
      }
    }
  }
`;

FeaturedContent.propTypes = featuredContentType;

export default FeaturedContent;
