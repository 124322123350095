import { string } from "prop-types";
import React from "react";

import BasicTitleDescription from "./BasicTitleDescription";
import CallToAction from "./CallToAction";
import CasesHighlight from "./CasesHighlight";
import Download from "./Download";
import FeaturedContent from "./FeaturedContent";
import Image from "./Image";
import ImageCarousel from "./ImageCarousel";
import InlineCarousel from "./InlineCarousel";
import NarrowTagline from "./NarrowTagline";
import Quote from "./Quote";
import Signup from "./Signup";
import TextColumns from "./TextColumns";
import TextImage from "./TextImage";
import Video from "./Video";

const components = {
  DatoCMS_BasicTitleDescriptionRecord: BasicTitleDescription,
  DatoCMS_CallToActionRecord: CallToAction,
  DatoCMS_ImageRecord: Image,
  DatoCMS_ImageCarouselRecord: ImageCarousel,
  DatoCMS_InlineCarouselRecord: InlineCarousel,
  DatoCMS_NarrowTaglineRecord: NarrowTagline,
  DatoCMS_QuoteRecord: Quote,
  DatoCMS_TextColumnRecord: TextColumns,
  DatoCMS_TextImageRecord: TextImage,
  DatoCMS_VideoRecord: Video,
  DatoCMS_SignupRecord: Signup,
  DatoCMS_DownloadRecord: Download,
  DatoCMS_FeaturedContentRecord: FeaturedContent,
  DatoCMS_CasesHighlightRecord: CasesHighlight,
};

const Modular = ({ type, ...props }) => {
  const Component = components[type];

  if (!Component) {
    throw new Error(
      `Tried to render a modular component for type ${type} but the component was not found.`
    );
  }

  return <Component {...props} html />;
};

Modular.propTypes = {
  type: string.isRequired,
};

export default Modular;
