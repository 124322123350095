import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { bool, string } from "prop-types";
import React from "react";
import { Col } from "react-flexbox-grid";

import theme from "../../theme";
import Button from "../Button";
import ContentWrapper from "../ContentWrapper";
import Heading from "../Heading";
import Icon from "../Icon";
import Link from "../Link";
import PageLink from "../PageLink";
import VerticalMargin from "../VerticalMargin";

const Wrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UnstyledAnchor = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

// @TODO: Add internal/external URL check
const CallToAction = ({ title, text, url, showAsLink }) => {
  return (
    <VerticalMargin>{renderCTA(title, text, url, showAsLink)}</VerticalMargin>
  );
};

const StyledHeading = styled(Heading)`
  margin-bottom: ${theme.space("xxs")};
`;

const renderCTA = (title, text, url, showAsLink) => {
  if (showAsLink) {
    return (
      <ContentWrapper columns={12}>
        <Col mdOffset={3}>
          {title && (
            <StyledHeading color={"text.dark"} size={3}>
              {title}
            </StyledHeading>
          )}
          <PageLink to={url} title={text} />
        </Col>
      </ContentWrapper>
    );
  } else {
    return (
      <Wrapper>
        {title && (
          <StyledHeading color={"text.dark"} size={3}>
            {title}
          </StyledHeading>
        )}
        <UnstyledAnchor to={url}>
          <Button size="large" styling="button" as="span">
            <span>{text}</span>
            <Icon type="arrow" />
          </Button>
        </UnstyledAnchor>
      </Wrapper>
    );
  }
};

export const CallToActionType = {
  text: string.isRequired,
  title: string.isRequired,
  url: string,
  showAsLink: bool,
};

export const callToActionFragment = graphql`
  fragment callToActionFragment on DatoCMS_CallToActionRecord {
    type: __typename
    id
    title
    text
    url
    showAsLink
  }
`;

CallToAction.propTypes = CallToActionType;

CallToAction.defaultProps = {
  showAsLink: false,
};
export default CallToAction;
