import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useRef } from "react";

import { CASES_PATH, UPDATES_PATH } from "../../constants";
import useInViewport from "../../hooks/useInViewport";
import createLink from "../../utils/createLink";
import TextBlockV1 from "../TextBlock/v1";
import VerticalMargin from "../VerticalMargin";

const formatSideLink = (sidelink, sidelinkText) => {
  if (!sidelink || !sidelinkText) return false;

  return {
    type: sidelink.type === "DatoCMS_UpdateRecord" ? "artikel" : "case",
    text: sidelinkText,
    as: createLink(
      `${
        sidelink.type === "DatoCMS_UpdateRecord" ? UPDATES_PATH : CASES_PATH
      }/${sidelink.slug}`
    ),
  };
};

const BasicTitleAndDescription = ({
  title,
  altHeading,
  description,
  largeDescription,
  sidelink,
  sidelinkText,
  ...props
}) => {
  const ref = useRef();
  const inView = useInViewport(ref, "-100px");

  const formattedSideLink = formatSideLink(sidelink, sidelinkText);

  return (
    <div ref={ref}>
      <VerticalMargin {...props}>
        <TextBlockV1
          title={title}
          text={description}
          html
          altHeading={altHeading}
          introduction={largeDescription}
          visible={inView ? 1 : 0}
          sideLink={formattedSideLink}
        />
      </VerticalMargin>
    </div>
  );
};

export const BasicTitleAndDescriptionType = {
  title: PropTypes.string,
  description: PropTypes.string,
  sidelink: PropTypes.object,
  sidelinkText: PropTypes.string,
  altHeading: PropTypes.bool,
  largeDescription: PropTypes.bool,
};

export const basicTitleDescriptionFragment = graphql`
  fragment basicTitleDescriptionFragment on DatoCMS_BasicTitleDescriptionRecord {
    type: __typename
    id
    title
    altHeading
    description
    largeDescription
    sidelink {
      type: __typename
      ... on DatoCMS_UpdateRecord {
        id
        slug
      }
      ... on DatoCMS_CaseRecord {
        id
        slug
      }
    }
    sidelinkText
  }
`;

BasicTitleAndDescription.propTypes = BasicTitleAndDescriptionType;

export default BasicTitleAndDescription;
