import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { bool, number, shape, string } from "prop-types";
import React, { useRef } from "react";

import useInViewport from "../../hooks/useInViewport";
import { datoFile } from "../../types";
import Button from "../Button";
import ContentBlock from "../ContentBlock/v1";
import ContentWrapper from "../ContentWrapper";
import Heading from "../Heading";
import Icon from "../Icon";
import Link from "../Link";
import Text from "../Text";
import VerticalMargin from "../VerticalMargin";

const UnstyledAnchor = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const TextAndImage = ({
  title,
  description,
  actionText,
  actionUrl,
  image,
  textIsLeft,
  titleSize,
  ...props
}) => {
  const ref = useRef();
  const inView = useInViewport(ref, "-100px");

  return (
    <div ref={ref}>
      <VerticalMargin {...props}>
        <ContentWrapper>
          <ContentBlock
            textLeft={textIsLeft}
            image={image}
            title={<Heading size={titleSize}>{title}</Heading>}
            text={
              <Text html as="div">
                {description}
              </Text>
            }
            visible={inView}
            action={
              actionUrl &&
              actionText && (
                <UnstyledAnchor to={actionUrl}>
                  <Button styling="link">
                    <span>{actionText}</span>
                    <Icon type="arrow" />
                  </Button>
                </UnstyledAnchor>
              )
            }
          />
        </ContentWrapper>
      </VerticalMargin>
    </div>
  );
};

export const TextAndImageType = {
  title: string,
  description: string,
  actionText: string,
  actionUrl: string,
  image: shape(datoFile),
  textIsLeft: bool,
  titleSize: number,
};

export const textImageFragment = graphql`
  fragment textImageFragment on DatoCMS_TextImageRecord {
    type: __typename
    id
    title
    description
    actionText
    actionUrl
    textIsLeft
    titleSize
    image {
      data: responsiveImage(
        imgixParams: { fit: crop, w: 600, h: 472, fm: jpg, auto: format }
      ) {
        ...responsiveImageFragment
      }
    }
  }
`;

TextAndImage.propTypes = TextAndImageType;

export default TextAndImage;
