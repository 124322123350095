import styled from "@emotion/styled";
import { object, shape, string } from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Image, { ImageType } from "../ImageComponent";
import NewsletterForm from "../NewsletterForm";
import { bodySmall, h3 } from "../Typography";

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mq("3")} {
    flex-direction: row;
  }
  background-color: ${theme.color("blue.light")};
`;

const ImageWrapper = styled.div`
  width: 100%;
  ${mq("3")} {
    width: 50%;
  }
  padding: ${theme.spacing("m")};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mq("3")} {
    width: ${({ hasImage }) => (hasImage ? "50%" : "100%")};
    flex-direction: ${({ hasImage }) => (hasImage ? "column" : "row")};
  }
  padding: ${theme.spacing("m")};

  > * {
    width: 100%;
    padding: 1rem;

    ${mq("3")} {
      width: ${({ hasImage }) => (hasImage ? "100%" : "50%")};
    }
  }
`;

const Title = styled.h4`
  ${h3}
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 0;
`;

const Text = styled.p`
  ${bodySmall}
`;

const StyledImage = styled(Image)`
  height: 100%;
  picture > img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

function SignUpComponent({
  title,
  text,
  buttonText,
  image,
  formLabels,
  ...props
}) {
  formLabels.newsletterButtonText =
    buttonText || formLabels.newsletterButtonText;

  return (
    <SignUpWrapper {...props}>
      {image && (
        <ImageWrapper>
          <StyledImage {...image} />
        </ImageWrapper>
      )}
      <ContentWrapper hasImage={!!image}>
        <div>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </div>
        <NewsletterForm
          backgroundColor={theme.color("blue.light")}
          formLabels={formLabels}
        />
      </ContentWrapper>
    </SignUpWrapper>
  );
}

SignUpComponent.propTypes = {
  title: string,
  text: string,
  buttonText: string,
  image: shape(ImageType),
  formLabels: object,
};

export default SignUpComponent;
