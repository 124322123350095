import { graphql } from "gatsby";
import { arrayOf, shape, string } from "prop-types";
import React from "react";

import { datoFile } from "../../types";
import Carousel from "../Carousel/Inline";
import Heading from "../Heading";
import Text from "../Text";
import VerticalMargin from "../VerticalMargin";

const InlineCarousel = ({ title, description, carouselImages, ...props }) => (
  <VerticalMargin {...props}>
    <Carousel
      title={<Heading size={2}>{title}</Heading>}
      text={<Text>{description}</Text>}
      images={carouselImages}
    />
  </VerticalMargin>
);

export const InlineCarouselType = {
  title: string,
  description: string,
  carouselImages: arrayOf(shape(datoFile)),
};

export const inlineCarouselFragment = graphql`
  fragment inlineCarouselFragment on DatoCMS_InlineCarouselRecord {
    type: __typename
    id
    title
    description
    carouselImages {
      data: responsiveImage(
        imgixParams: { fit: crop, w: 444, h: 270, fm: jpg, auto: format }
      ) {
        ...responsiveImageFragment
      }
    }
  }
`;

InlineCarousel.propTypes = InlineCarouselType;

export default InlineCarousel;
