import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { bool, shape, string } from "prop-types";
import React from "react";

import ContentWrapper from "../ContentWrapper";
import VerticalMargin from "../VerticalMargin";
import VideoPlayer from "../VideoPlayer";

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

const Video = ({ source, loop, autoplay, controls }) => (
  <VerticalMargin>
    <ContentWrapper>
      <VideoWrapper>
        <VideoPlayer
          url={source.url}
          loop={loop}
          autoplay={autoplay}
          controls={controls}
        />
      </VideoWrapper>
    </ContentWrapper>
  </VerticalMargin>
);

export const VideoType = {
  source: shape({
    url: string,
  }),
  loop: bool,
  autoplay: bool,
  controls: bool,
};

export const videoFragment = graphql`
  fragment videoFragment on DatoCMS_VideoRecord {
    type: __typename
    id
    source {
      url
    }
    loop
    autoplay
    controls
  }
`;

Video.propTypes = VideoType;

export default Video;
