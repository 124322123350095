import { graphql } from "gatsby";
import { shape, string } from "prop-types";
import React from "react";

import ContentWrapper from "../ContentWrapper";
import { ImageType } from "../ImageComponent";
import SignUpComponent from "../SignUpComponent";
import VerticalMargin from "../VerticalMargin";

const Signup = (props) => (
  <VerticalMargin>
    <ContentWrapper columns={12}>
      <SignUpComponent html {...props} />
    </ContentWrapper>
  </VerticalMargin>
);

export const SignupType = {
  newsletterUrl: string,
  image: shape(ImageType),
  text: string,
  title: string,
  buttonText: string,
};

export const signupFragment = graphql`
  fragment signupFragment on DatoCMS_SignupRecord {
    type: __typename
    newsletterUrl
    image {
      data: responsiveImage(
        imgixParams: { fit: crop, w: 600, h: 472, fm: jpg, auto: format }
      ) {
        ...responsiveImageFragment
      }
    }
    title
    text
    buttonText
  }
`;

Signup.propTypes = SignupType;

export default Signup;
