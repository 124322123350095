import styled from "@emotion/styled";
import { shape, string } from "prop-types";
import React from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Image, { ImageType } from "../ImageComponent";
import PillButton from "../PillButton";
import { bodySmall, h3 } from "../Typography";

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mq("3")} {
    flex-direction: row;
  }
  background-color: ${theme.color("blue.light")};
`;

const ImageWrapper = styled.div`
  width: 100%;
  ${mq("3")} {
    width: 50%;
  }
  padding: ${theme.spacing("m")};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${mq("3")} {
    width: ${({ hasImage }) => (hasImage ? "50%" : "100%")};
    flex-direction: ${({ hasImage }) => (hasImage ? "column" : "row")};
  }
  padding: ${theme.spacing("m")};

  > * {
    width: 100%;
    padding: 1rem;

    ${mq("3")} {
      width: ${({ hasImage }) => (hasImage ? "100%" : "50%")};
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Title = styled.h4`
  ${h3}
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
`;

const Text = styled.p`
  ${bodySmall}
  margin: 0 0 1rem;
`;

const StyledImage = styled(Image)`
  height: 100%;
  picture > img {
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`;

function DownloadComponent({
  title,
  text,
  buttonText,
  image,
  asset,
  link,
  linkLabel,
  ...props
}) {
  return (
    <SignUpWrapper {...props}>
      {image && (
        <ImageWrapper>
          <StyledImage {...image} />
        </ImageWrapper>
      )}
      <ContentWrapper hasImage={!!image}>
        <Content>
          <Title>{title}</Title>
          {text && <Text>{text}</Text>}
          {asset?.url && asset?.title && (
            <PillButton
              as="a"
              appearance="default"
              label={buttonText}
              href={asset.url}
              download={asset.title}
            />
          )}
          {link && linkLabel && (
            <PillButton
              as="a"
              appearance="default"
              label={linkLabel}
              href={link}
              target="_blank"
            />
          )}
        </Content>
      </ContentWrapper>
    </SignUpWrapper>
  );
}

DownloadComponent.propTypes = {
  title: string.isRequired,
  text: string,
  buttonText: string.isRequired,
  image: shape(ImageType).isRequired,
  asset: shape({
    url: string,
    title: string,
  }),
  link: string,
  linkLabel: string,
};

export default DownloadComponent;
