import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import theme from "../../theme";
import formatCase from "../../utils/formatters/formatCase";
import CaseGrid from "../CaseGrid";
import ContentWrapper from "../ContentWrapper";
import Heading from "../Heading";

const CasesHighlight = ({ title, cases }) => (
  <ContentWrapper columns={12}>
    <CaseGrid
      heading={
        title && (
          <>
            <Heading style={{ marginBottom: theme.spacing("m") }} size={2}>
              {title}
            </Heading>
          </>
        )
      }
      items={cases.map((caseItem) => formatCase(caseItem))}
    />
  </ContentWrapper>
);

CasesHighlight.propTypes = {
  title: PropTypes.string,
  cases: PropTypes.array,
};

export const casesHighlightFragment = graphql`
  fragment casesHighlightFragment on DatoCMS_CasesHighlightRecord {
    type: __typename
    id
    title
    cases {
      ...caseFragment
    }
  }
`;

export default CasesHighlight;
