import { graphql } from "gatsby";
import { string } from "prop-types";
import React from "react";

import TextBlock from "../TextBlock/v3";
import VerticalMargin from "../VerticalMargin";

const TextColumns = ({
  title,
  intro,
  descriptionLeft,
  descriptionRight,
  ...props
}) => (
  <VerticalMargin {...props}>
    <TextBlock
      title={title}
      intro={intro}
      textLeft={descriptionLeft}
      textRight={descriptionRight}
      html
    />
  </VerticalMargin>
);

export const TextColumnsType = {
  title: string,
  intro: string,
  descriptionLeft: string,
  descriptionRight: string,
};

export const textColumnFragment = graphql`
  fragment textColumnFragment on DatoCMS_TextColumnRecord {
    type: __typename
    id
    title
    intro
    descriptionLeft
    descriptionRight
  }
`;

TextColumns.propTypes = TextColumnsType;

export default TextColumns;
